.services_second_section {
  padding: 100px 0;

  .aroundTextImg{
    display: none;
  }

  .title {
    font-size: 25px;
    font-weight: bold;
  }

  .subtitle {
    //padding-right: 50px;
  }

  img {
    border-radius: 10px;
    width: 100%;
  }

  .section-1 {
    padding-right: 50px;
  }

  .ol-wrapper {
    display: flex;
    counter-reset: item;

    ol {
      line-height: 2;
      padding: 0;
      margin-bottom: 0;
    }

    ol:not(:last-child) {
      margin-right: 40px;
    }

    ol li {
      list-style-type: none;
      counter-increment: item;
      display: flex;
      align-items: flex-start;
      line-height: 1.4;
      margin-bottom: 8px;
      min-height: 45px;
    }

    ol li:before {
      content: counter(item);
      display: inline-block;
      width: 40px;
      height: 30px;
      margin-right: 5px;
      background-color: var(--primary);
      color: white;
      font-weight: bold;
      font-size: 140%;
      padding: 0 8px 8px;
      border-radius: 3px;
      line-height: 1.3;
      text-align: center;
      flex-shrink: 0;
    }
  }

  .section-2 {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: 992px) {
    padding: 25px 0;
  }

  @media screen and (max-width: 767px) {
    padding: 10px 0;

    .aroundTextImg {
      display: block;
    }

    .section-1 {
      padding-right: 0;
      //text-align: justify;
    }

    .section-2 {
      display: none;
    }

    .ol-wrapper {
      flex-direction: column;
      margin-bottom: 10px;

      ol {
        margin: 0;
      }
    }
  }
}
