.second_section {
  padding: 0px;

  .cards {
    position: relative;
    border-radius: 10px;
    padding: 15px 25px;
    margin-top: -50px;
    z-index: 2;

    .card_cont {
      padding: 0 10px;

      .cardd {
        height: 100%;
        border-radius: 5px;
        box-shadow: 0 2px 29px 0 rgba(15, 46, 65, 0.12);
        background-color: black;
        padding: 30px;

        img {
          height: 60px;
          width: 60px;
          margin-bottom: 30px;
        }

        .icon {
          font-size: 40px;
          color: white;
          margin-bottom: 30px;
        }

        .text {
          text-align: center;
          color: white;

          .title {
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            margin: 0;
            margin-bottom: 10px;
          }

          .subtitle {
            font-size: 14px;
            line-height: 1.5em;
            letter-spacing: 0.5px;
            font-weight: lighter;
          }
        }
      }

      .first {
        background-color: var(--secondary);
      }

      .second {
        background-color: var(--primary);
      }

      .third {
        background-color: white;

        .text, .icon {
          color: var(--primary);
        }
      }
    }
  }

  .learn-more-btn {
    display: inline-flex;
    text-decoration: none;
    color: var(--secondary);

    &:hover {
      color: var(--primary);
      text-decoration: underline;
    }

  }

  .second_section_about {
    padding: 50px 0;

    .title {
      text-align: center;
      border-right: 1px solid gray;

      h1 {
        font-weight: 600;
        font-size: 40px;
      }
    }

    .subtitle {
      padding-left: 40px;

      p {
        margin: 0;
        font-size: 14px;
      }
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1000px) {

  }
  @media screen and (max-width: 767px) {
    .cards {
      padding: 0;

      .ant-col {
        margin-bottom: 30px;
      }
    }
    .second_section_about {
      .title {
        border: none;
      }

      .subtitle {
        padding: 0;
        text-align: center;
      }
    }
  }
}
