ul {
  li {
    list-style-type: none;
  }
}

a {
  text-decoration: none;
}

.white_dash {
  height: 2px;
  width: 50px;
  background-color: white;
  margin: 30px auto;
}

.orange_dash {
  height: 2px;
  width: 50px;
  background-color: var(--secondary);
  margin: 30px auto;
}

.my-container {
  max-width: 1200px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 992px) {
    max-width: 960px;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.breadcrumb {
  justify-content: center;

  .breadcrumb_items {
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;

    a {
      color: white;
      text-decoration: none;
    }

    color: var(--secondary);
  }

  .ant-breadcrumb-separator {
    color: white;
  }
}

.upIcon {
  background-color: var(--secondary);
  color: white;
  padding: 13px 10px;
  border-radius: 50%;
  font-size: x-large;

  &:hover {
    background-color: var(--secondary-hover);
  }
}

.bodyOverflowH {
  @media (max-width: 992px) {
    overflow: hidden;
  }
}