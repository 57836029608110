.fifth_section {
  background-image: url("../../../../public/testm.jpg");
  background-size: cover;
  .parallax_bgc {
    padding: 100px 0 150px;
    background-color: rgba(5, 24, 41, 0.66);

    .title {
      text-align: center;
      color: white;
      font-weight: 700;
    }
  }
  @media screen and (max-width: 767px){
    .parallax_bgc {
      padding: 50px 0;
    h1 {
      font-size: 40px;
    }
    }
  }
}
