.fourth_section {
  .my-container2 {
    max-width: 1300px;
    padding-left: 50px;
    padding-right: 50px;
    margin-left: auto;
    margin-right: auto;

  }

  .parallax_bgc {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 100px 0;

    h1 {
      text-align: center;
      color: white;
      font-weight: bold;
    }

    .count_up {
      justify-content: space-between;

      .count_cont {
        color: white;
        display: flex;
        align-items: center;
        height: 100px;

        .icon {
          height: 50px;
        }

        .number {
          font-size: 40px;
          font-weight: 600;
          margin: 0;
        }

        .description {
          font-size: 16px;
          font-weight: 300;
        }

        p {
          line-height: 1.2em;
          padding-left: 30px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .parallax_bgc {
      padding: 50px 0;

      h1 {
        font-size: 40px;
        font-weight: 700;
      }
      .white_dash {
        margin-bottom: 50px;
      }

      .count_up {
        flex-direction: column;
        align-items: center;
        .count_cont {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 110px;
          .count_text {
            display: flex;
            flex-direction: column;
            align-items: center;
            .number {
              margin: 20px 0 10px;
            }
          }

          p {
            padding: 0;
          }
        }
      }
    }
  }
}
