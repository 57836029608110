.sixth_section {
    background-color: var(--primary);
  .get_in_touch {
    width: 100%;
    background-color: #fff;
    background-image: url("../../../../public/customsol.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    border-radius: 7px;
    margin-top: -90px;
    position: relative;

    .git_text {
      padding: 40px;

      .git_title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .git_subtitle {
        font-size: 16px;
        line-height: 1.65em;
        font-weight: 300;
        margin: 0;
      }
    }

    .button_cont {
      display: flex;
      justify-content: center;
      align-items: center;

      .button {
        padding: 10px 20px;
        border: 1px solid var(--primary);
        border-radius: 5px;
        background-color: #fff;
        color: var(--primary);
        transition: .4s;
        font-weight: 600;

        &:hover {
          background-color: var(--primary);
          color: white;
        }
      }
        a {
          text-decoration: none;
        }
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1000px) {
    .get_in_touch {
      padding-bottom: 20px;
    .git_text {
      padding-bottom: 0px;
      margin-bottom: 15px;
    }
    }
  }
  @media screen and (max-width: 767px) {
    margin-top: 80px;
    .my-container {
      padding: 0;
    }
    .get_in_touch {
      background-image: none;
      border-radius: 0;
      padding-bottom: 30px;
    }
  }
}