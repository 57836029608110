.about-second-section {
  padding: 55px 0 55px;

  .left-col {

    img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .right-col {

    .content {
      margin-top: 0;
      margin-bottom: 16px;

      .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 8px;
        margin-top: 0;
      }

      .text {
        text-align: justify;
        font-size: 16px;
        font-weight: 300;
        line-height: 26px;
      }
    }

    .content:first-child {
      margin-bottom: 32px;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style: inside;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
      }
    }
  }

  @media screen and (max-width: 992px) {
    & {
      padding: 40px 0;
    }
  }

  @media screen and (max-width: 768px) {
    & {
      padding: 40px 0 20px;

      .right-col {
        .content {
          .text {
            line-height: normal;
          }
        }
      }
    }
  }
}
