.mySwiper {
  .swiper-wrapper {
    align-items: stretch;
  }
  .swiper-slide {
    display: flex;
    flex-direction: column;
    flex-grow: 1 !important;
  }

  .my-slide-item {
    height: 100%;
    flex-grow: 1;
  }

  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .icon {
    color: var(--secondary);
    font-size: 15px;
    margin-right: 3px;
  }
  .text {
    display: flex;
    flex-direction: column;


    text-align: start;
    padding: 0  10px;
    .description {
      font-size: 14px;
      font-style: italic;

      flex-grow: 1;
    }
    .name {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 0px;
      color: var(--secondary);
    }
    .profession {
      font-size: 16px;
      font-weight: 300;
    }
  }
  .swiper_cont {
    .swiper {
      width: 100%;
      height: 100%;
    }
    .swiper-slide {
      margin-right: 0;
      padding-right: 20px;
      padding-left: 20px;
      min-height: 200px;

      &:active {
        cursor: grabbing;
      }
      * {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: transparent;
      color: white;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .swiper-pagination-bullet-active {
    background-color: white !important;
    width: 30px;
    border-radius: 5px;
  }
  .swiper-pagination-bullet {
    border: 1px solid white;
    opacity: unset;
    background-color: transparent;
  }
  .swiper-pagination {
    margin-top: 50px;
  }
}
