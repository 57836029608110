.third_section {
  background-color: var(--primary);

  .left_side {
    height: 100%;
    background-image: url("../../../../public/bg-image2.jpg");
    background-size: 180% 100%;
    background-position: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .text_cont {
      background-color: var(--secondary);
      padding: 50px;
      text-align: center;
      max-width: 300px;

      .title {
        font-size: 35px;
        line-height: 1.1em;
        color: white;
        font-weight: 700;
      }

    }

  }

  .right_side {
    padding: 50px;

    .main_title {
      .title {
        font-size: 40px;
        color: white;
        font-weight: 600;
        text-align: center;
      }
    }

    .cards {
      .card_text {
        padding: 0 20px 20px 0;
      }

      .icon {
        height: 50px;
        margin-right: 30px;
      }

      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .subtitle {
        font-size: 15px;
        line-height: 1.5;
        color: #dedede;
      }

      p {
        color: white;
      }

    }
  }

  @media screen and (min-width: 767px) and (max-width: 1200px) {
    .left_side {
      height: 350px;
      background-size: cover;

      .text_cont {
        padding-bottom: 20px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .left_side {
      background-color: var(--secondary);
      background-image: none;

      .text_cont {
        padding: 40px 10px 10px;
      }
    }
    .right_side {
      .cards {
        .ant-col {
          display: flex;
          flex-direction: column;
          text-align: center;

          .icon {
            font-size: 50px;
            margin: 0;
            margin: 20px 0;
          }

          .card_text {
            padding: 0;

            .title {
              margin: 0 0 20px;
            }

            .subtitle {
              color: #c2c2c2;
              line-height: 1.4em;
            }
          }
        }
      }
    }
  }
}