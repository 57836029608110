.about-third-section {
  padding: 55px 0 110px;

  .item {
    display: flex;

    .img-wrapper {
      width: 200px;
      height: 200px;
      flex-shrink: 0;
      margin-right: 20px;
      img {
        width: 100%;
        height: auto;
      }
    }

    .title {
      font-size: 24px;
      line-height: 28px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 8px;
    }

    .text {
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      text-align: justify;
    }
  }

  @media screen and (max-width: 992px) {
    & {
      padding: 50px 0;

      .ant-row {
        row-gap: 30px !important;
      }

      .item {
        flex-direction: column;
        align-items: center;

        .img-wrapper {
          width: 100%;
          height: auto;
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    & {
      padding: 40px 0 35px;

      .ant-col:not(:first-child) {
        margin-top: -30px;
      }

      .item {
        flex-direction: column;
        align-items: center;

        .img-wrapper {
          width: 100%;
          height: auto;
          margin-right: 0;
          margin-bottom: 10px;
        }

        .text {
          line-height: normal;
        }
      }
    }
  }
}
