.footer-wrapper {
  padding: 100px 0 110px;
  background-color: var(--primary);
  padding: 110px 0;
  background-color: #051829;
  color: #fff;

  img {
    max-width: 100%;
    height: auto;
  }

  .logo-wrapper {
    max-width: 200px;
    height: auto;
    margin-bottom: 21px;

    a {
      display: block;
    }
  }

  .first-col {
    p {
      margin-top: 0;
      margin-bottom: 30px;
      font-weight: 300;
      padding-right: 20px;
    }

    .social-links-list {
      padding: 0;
      margin: 0;
      display: flex;

      .item:not(:last-child) {
        margin-right: 10px;
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid var(--secondary);
        width: 32px;
        height: 32px;
        transition: border-color 0.5s ease;

        svg {
          color: #fff;
          transition: color 0.5s ease;

          width: 16px;
          height: 16px;
        }

        &:hover {
          border-color: #fff;

          svg {
            color: var(--secondary)
          }
        }
      }
    }
  }

  .second-col {
    font-weight: 300;
    padding-top: 69px;
  }

  .third-col {
    padding: 0 15px;

    .list {
      padding: 0;
      margin: 0;
    }

    .item {
      a {
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        font-weight: 300;

        &:hover {
          color: var(--secondary);
        }
      }
    }
  }

  .fourth-col {
    .list {
      padding: 0;
      margin: 0;
    }

    .item:not(:last-child) {
      margin-bottom: 10px;
    }

    .item {
      display: flex;
      align-items: start;
      font-weight: 300;

      .link-tel {
        text-decoration: none;
        color: inherit;

        &:hover {
          color: var(--secondary);
        }
      }

      .svgWrapper {
        width: 24px;
        height: 24px;
        margin-right: 9px;
        position: relative;
        flex-shrink: 0;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);

          color: var(--secondary);
        }
      }
      a {
        text-decoration: none;
        color: white;
      }
    }
  }

  .title {
    font-size: 24px;
    color: #fff;
    position: relative;

    padding-bottom: 20px;
    margin-bottom: 20px;

    &::after {
      width: 30px;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: var(--secondary);

      content: "";
    }
  }

  @media screen and (max-width: 767px) {
    padding: 50px 0;

    .ant-row {
      flex-direction: column;

      .title::after {
        left: 50%;
        transform: translateX(-50%);
      }

      .first-col {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;

        .logo-wrapper {
          margin-bottom: 20px;
        }
        p {
          text-align: center;
          margin-bottom: 20px;
        }
      }

      .second-col {
        padding-top: 40px;
        text-align: center;
      }

      .third-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 10px;

        .list {
          .item {
            text-align: center;
          }
        }
      }

      .fourth-col {
        display: flex;
        flex-direction: column;
        align-items: center;

        .list {
          .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            .svgWrapper {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.footer-bottom-row {
  background-color: #fff;
  padding: 10px 0;

  p {
    padding: 0;
    margin: 0;
    text-align: center;
    color: #303030;
    font-size: 14px;
    font-weight: 300;
    line-height: 26px;
  }
  a {
    text-decoration: none;
    color: black;
  }
}
