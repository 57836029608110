.contact-second-section {
  padding-top: 110px;
  padding-bottom: 40px;
  margin-bottom: 40px;

  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 26px;
    text-align: center;
  }

  .text {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    text-align: center;
  }

  @media screen and (max-width: 992px) {
    padding-top: 80px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 50px;
    margin-bottom: 0;

    .text {
      line-height: normal;
    }
  }
}
