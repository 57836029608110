.first_section {
  background-image: url("../../../../public/ban_bg (2).webp");
  background-size: cover;
  background-position: 100% 50%;
  position: relative;
  height: 100vh;
  width: 100%;

  &-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .bgi {
    //background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .text {
    margin-bottom: 50px;

    h1 {
      padding: 0;
      color: white;
      font-size: 50px;
      font-weight: 700;
    }

    .subtitle {
      color: white;

    }
  }


  .button {
    padding: 10px 50px;
    border-radius: 5px;
    border: 0;
    background-color: var(--secondary);
    color: white;
    font-weight: bold;
    text-transform: capitalize;
    text-decoration: none;

    &:hover {
      background-color: var(--primary);
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1200px) {
    background-position: 0 0;

  }
  @media screen and (max-width: 767px) {
    background-position: 60% 0;
    &-container {
    }
    .text {
      h1 {
        font-size: 30px !important;
        font-weight: 500 !important;
        margin-bottom: 30px;
      }
    }
  }
}
