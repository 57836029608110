.hero {
  height: 390px;
  overflow: hidden;
  position: relative;

  &__bgi {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;

    // NOSELECT FOR BGI
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      opacity: 0.4;

      background-color: #000;
      content: "";
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.2;
    text-align: center;
    color: #fff;
    margin-top: 0;
    margin-bottom: 8px;
  }

  &__content {
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}
