@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  font-family: 'Open Sans', sans-serif;
}
