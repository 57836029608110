.services_first_section {
  height: 50vh;
  background: linear-gradient(rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.63)), url("../../../../public/bg-image2.jpg");
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    color: white;
    text-align: center;
  }

  @media screen and (max-width: 767px){
    & > div {
      transform: translateY(15px);
    }

    h1 {
      font-size: 24px;
    }
  }
}
