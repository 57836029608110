.contact-third-section {
  padding-bottom: 110px;

  .map-side {
    .map {
      max-height: 300px;
      height: 100%;
    }
  }

  .form-side {
    .input {
      width: 100%;
      padding: 10px;
      border: 1px solid #f0f0f0;
      margin-top: 15px;
      &::placeholder {
        color: #696969;
        font-size: 16px;
      }
    }

    .input-textarea {
      resize: vertical;
      min-width: 150px;
      min-height: 100px;
      width: 100%;
      border: 1px solid #f0f0f0;
      margin-top: 15px;
      padding: 10px;
      &::placeholder {
        color: #696969;
        font-size: 16px;
      }
    }
    .submit {
        background-color: var(--secondary);
      border: 0;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      padding: 6px 12px;
      height: auto;
      color: white;
      &:hover {
      background-color: var(--primary);
      }
    }
  }

  .title {
    font-size: 24px;
    line-height: 26px;
    font-weight: 600;
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;

    &::before {
      position: absolute;
      left: 0;
      bottom: 0;

      width: 30px;
      height: 2px;
      background-color: var(--secondary);
      content: "";
    }
  }
  .ymaps-2-1-79-map-copyrights-promo, .ymaps-2-1-79-copyright__layout {
    display: none;
  }

  @media screen and (max-width: 992px) {
    & {
      padding-bottom: 80px;
    }
  }

  @media screen and (max-width: 767px) {
    & {
      padding-bottom: 50px;
      .map-side {
        iframe {
          height: 300px;
          width: 100%;
        }
      }

      .pe-2 {
        padding: 0 !important;
      }

      .ps-2 {
        padding: 0 !important;
      }
    }
  }
}
