.header_wrapper {
  background-color: rgba(0, 0, 0, 0.28);
  z-index: 10;

  .my-container {
    position: relative;
  }

  .header_contacts {
    width: 100%;
    background-color: var(--primary);
    padding: 10px 20px;
    transition: all 0.3s ease;
    overflow: hidden;
    a {
      color: white;
      text-decoration: none;
    }

    p {
      padding: 0;
      margin: 0;
      color: #fff;
      font-size: 14px;

      @media screen and (max-width: 768px) {
        font-size: 9px;
      }
    }

    &-container {
      max-width: 1500px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .header_cont {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      padding: 10px 0;

      img {
        height: 30px;
      }
    }

    .navbar {
      position: static;
      padding: 0;

      ul {
        display: flex;
        margin: 0;


        li {
          margin: 0;
          position: relative;
          display: flex;
          align-items: center;
          margin-left: 28px;

          .navbar__link {
            color: white;
            text-transform: uppercase;
            text-decoration: none;
            font-family: 'Work Sans', sans-serif;
            font-size: 13px;
            padding: 25px 0 20px;
            white-space: nowrap;
            display: block;
            border-bottom: 2px solid transparent;

            &:hover {
              border-bottom: 2px solid white;
            }

            &.active {
              color: var(--secondary);
              border-bottom: 2px solid var(--secondary);
            }
          }

          .appointment {
            background-color: var(--secondary);
            padding: 11px 15px !important;
            border-radius: 5px;
            color: white !important;
            font-weight: bold;
            font-size: 12px;

            &:hover {
              background-color: var(--primary);
              border-bottom: none;
            }

            &.active {
              border: none;
            }
          }

          .activeDropdown {

          }

          .dropdown {
            position: absolute;
            bottom: 0;
            left: -28px;
            transform: translateY(120%);
            transition: transform 0s ease-in-out,
            opacity 0s ease, visibility 0s ease-in-out;
            opacity: 0 !important;
            visibility: hidden;

            width: 250px;
            background-color: rgba(5, 24, 41, 0.97);

            .list {
              display: flex;
              flex-wrap: wrap;
              padding: 0;
              margin: 0;

              li {
                border-bottom: 1px solid #303030;
                width: 100%;
                margin-left: 0;
                padding-left: 18px;

                a {
                  text-decoration: none;
                  display: block;
                  padding: 10px;
                  color: #fff;

                  &:first-letter {
                    text-transform: uppercase;
                  }
                }

                &:hover {
                  background-color: var(--secondary);
                }
              }
            }
          }

          &:hover {
            .dropdown {
              transform: translateY(100%);
              opacity: 1 !important;
              visibility: visible;

              transition: transform 0.3s ease-in-out,
              opacity 0.3s ease, visibility 0.3s ease-in-out;
            }
          }
        }

        @media (max-width: 992px) {
          display: none !important;
        }
      }
    }
  }

}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(0);
  transition: transform 0.6s ease, background-color 0.2s ease;

  &.burgerOpenNav {
    background-color: var(--primary);
  }

  .appointment {
    border-bottom: 0 !important;
  }
}

.header2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.97);
  box-shadow: 0px 5px 19px 0px rgba(0, 0, 0, 0.27);
  transform: translateY(10);
  transition: background-color 0.2s ease;
  animation: myanimation 3s;
  .appointment {
    border-bottom: 0 !important;

    &.active {
      border: none !important;
    }

    &:hover {
      background-color: var(--primary) !important;
      border-bottom: none !important;
    }
  }

  .burger {
    &::after, &::before {
      background-color: var(--primary);
    }

    span {
      background-color: var(--primary);
    }
  }

  @media (max-width: 992px) {
    &.burgerOpenNav {
      box-shadow: none;
    }
  }


  .header_contacts {
    display: none;
  }

  .header_cont {
    .navbar {
      ul {
        li {
          .navbar__link {
            color: var(--primary);
            padding: 20px 0 16px;
            border-bottom: 5px solid transparent;

            &:hover {
              border-bottom: 5px solid var(--primary);
            }

            &.active {
              color: var(--secondary);
              border-bottom: 5px solid var(--secondary);
            }
          }
        }
      }
    }
  }
}

@keyframes myanimation {
  0% {
    background-color: transparent;
    color: white;
  }
  35% {
    background-color: rgba(255, 255, 255, 0.97);
    color: black;
  }
  100% {
    background-color: rgba(255, 255, 255, 0.97);
    color: black;
  }
}

.burger {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: none;

  span {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(1);

    width: 100%;
    height: 3px;
    opacity: 1;
    transition: opacity 0.3s ease, transform 0.3s ease;
    background-color: #fff;
  }

  &::before, &::after {
    position: absolute;
    left: 0;
    background-color: #fff;
    transition: transform 0.3s ease;

    width: 100%;
    height: 3px;
    content: "";
  }

  &::before {
    top: 5px;
    transform-origin: top left;
  }

  &::after {
    bottom: 5px;
    transform-origin: bottom left;
  }

  &.open {
    span {
      opacity: 1;
      transform: scale(0);
    }

    &::before {
      transform: translate(6px) rotate(45deg);
    }

    &::after {
      transform: translate(6px) rotate(-45deg);
    }
  }

  @media (max-width: 992px) {
    display: block;
  }
}

.accordion-list {
  display: none;
}

@media (max-width: 992px) {
  .burgerOpenNav {
    overflow-y: auto;

    .header_contacts {
      height: 0;
      padding: 0;
    }

    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .accordion-list {

    }
  }

  .header_wrapper {
    .accordion-list {
      display: flex;
      height: 0;
      overflow: hidden;
      transition: all 0.5s ease;
      width: 100%;

      .myaccordion {
        flex-grow: 1;
        padding: 2px 15px;
        margin: 0;

        .myaccordion-item {
          flex-grow: 1;
          display: flex;
          flex-wrap: wrap;

          a {
            width: 100%;
            padding: 4px 0;
            font-size: 18px;
            line-height: 32px;
            text-decoration: none;
          }
        }

        .my-acc {
          flex-direction: column;

          .dropdown {
            width: 100%;
            height: 0px;
            overflow: hidden;
            transition: all 0.3s ease;

            .list {
              li {
                display: flex;

                a {
                  padding: 4px 0;
                  line-height: 32px;
                  font-size: 18px;

                  &:first-letter {
                    text-transform: uppercase;
                  }
                }

              }
            }
          }
        }

        .activeDrop {
          &.w200 {
            .dropdown {
              height: 200px;
            }
          }

          &.w120 {
            .dropdown {
              height: 120px;
            }
          }

          &.w240 {
            .dropdown {
              height: 240px;
            }
          }

          & > a {
            color: var(--secondary) !important;
          }
        }

        // ACCORDION ICON
        .my-acc {
          & > a {
            position: relative;

            &::after {
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%) rotate(45deg);
              transition: all 0.3s ease;

              width: 15px;
              height: 15px;
              border-right: 2px solid transparent;
              border-bottom: 2px solid transparent;

              content: "";
            }
          }

          &.activeDrop {
            & > a {
              &::after {
                transform: rotate(225deg);
              }
            }
          }
        }
      }
    }
  }

  .header {
    .accordion-list {
      top: 84px;
      position: sticky;
      top: 56px;

      .myaccordion {
        .myaccordion-item {
          a {
            color: #fff;

            &:hover {
              color: var(--secondary);
            }
          }

          & > a {
            &::after {
              border-color: #fff;
            }
          }

          &.activeDrop {
            & > a {
              &::after {
                border-color: var(--secondary);
              }
            }
          }
        }
      }

      &.acc-open {
        height: 500px !important;
      }
    }
  }

  .header2 {
    .accordion-list {
      position: fixed;
      top: 56px;

      .myaccordion {
        background-color: rgba(255, 255, 255, 0.97);

        .myaccordion-item {
          a {
            color: var(--primary);

            &:hover {
              color: var(--secondary);
            }
          }

          & > a {
            &::after {
              border-color: var(--primary);
            }
          }

          &.activeDrop {
            & > a {
              &::after {
                border-color: var(--secondary);
              }
            }
          }
        }
      }

      &.acc-open {
        height: 500px !important;
      }
    }
  }
}
